<template>
  <page-main
    :title="
      `Now, the really important stuff: Your accomplishments at ${companyName}`
    "
    :aside="[
      'Accomplishments are a great way to show the impact you had and showcase what you bring to the company you are applying to! ',
      `Take some time to think about your accomplishments at ${companyName}, then use the tool to present them in the best way.`,
      'It is best to list only two or three key accomplishments. Focus on accomplishments that were the most impactful, and that best fit the role you are trying to attain.',
      'It is better to not list a mediocre accomplishment - make sure the relevant skills are covered in your responsibilities section, and make sure the accomplishments you list here really shine.'
    ]"
    nextText="Done? Go back to the Work overview."
    :buttonTabIndex="nextti"
    eventName="nav"
    @nav="nav('/Work')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-expansion-panels v-model="panel">
            <r-expansion-panel v-for="(item, i) in achievements" :key="i">
              <a class="anchor" :id="'panel-' + i"></a>
              <r-expansion-panel-header>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    {{ buildTitleString(item) }}
                  </div>
                  <div class="pr-3 pl-2">
                    <v-btn
                      outlined
                      color="white"
                      @click.stop="
                        launchDialog(item.id, buildTitleString(item))
                      "
                      >Remove</v-btn
                    >
                  </div>
                </div>
              </r-expansion-panel-header>
              <r-expansion-panel-content>
                <v-container>
                  <v-row class="pt-4">
                    <v-col cols="12" class="r-col">
                      <p class="text-body-1 font-italic text--secondary">
                        The questions below are designed to help you present
                        your accomplishment in a way that clear and interesting
                        to decision makers reviewing your resume.
                      </p>
                      <p class="text-body-1 font-italic text--secondary">
                        Be prepared to further expand on these accomplishments
                        during an interview.
                      </p>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-textarea
                        v-model="item.background"
                        rows="1"
                        autogrow
                        label="What is the background of this accomplishment? (One sentence)."
                      ></r-textarea>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-textarea
                        v-model="item.responsibility"
                        rows="1"
                        autogrow
                        label="What was your role or responsibility? (One sentence)."
                      ></r-textarea>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-textarea
                        v-model="item.actionResult"
                        rows="1"
                        autogrow
                        label="What action did you take, or what was the result? (One or two sentences)"
                      ></r-textarea>
                    </v-col>
                    <v-col cols="12" class="r-col">
                      <r-textarea
                        v-model="item.measurement"
                        rows="1"
                        autogrow
                        label="Describe the measured dollar amount, quantity, percent increase / decrease, or other measurement related to the outcome. (If applicable)"
                      ></r-textarea>
                    </v-col>
                    <v-col cols="12" class="pl-5">
                      <h3>Suggested Phrasing:</h3>
                      <p class="text-body-1">{{ item.displayAuto }}</p>
                      <v-switch
                        v-model="item.useCustom"
                        label="Need to make an adjustment?"
                      ></v-switch>
                      <transition name="line-66">
                        <v-textarea
                          v-if="item.useCustom"
                          v-model="item.displayCustom"
                          rows="3"
                          auto-grow
                          label="Adjust the phrase"
                        ></v-textarea>
                      </transition>
                    </v-col>
                  </v-row>
                </v-container>
              </r-expansion-panel-content>
            </r-expansion-panel>
          </r-expansion-panels>
        </v-col>
        <v-col cols="12" class="r-col d-flex justify-center">
          <v-btn outlined @click="addAchievement()"
            >Add New Accomplishment</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <r-dialog
      v-model="dialog"
      title="You are about to remove this accomplishment:"
    >
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary">{{ dialogDisplay }}</p>
        <span class="text-body-2">Are you sure?</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="error" text @click="removeDialog()"> Remove </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import * as uuid from "../utility/guid";
import * as disp from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapMutations, mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "WorkAccomplishments",
  components: {},
  data: () => ({
    panel: 0,
    dialog: false,
    dialogId: "",
    dialogDisplay: "",
    interval: null,
  }),
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }

    if (this.achievements.length == 0) {
      this.addAchievement();
    }

    // set up auto-save 30s interval
    this.interval = setInterval(() => this.saveWork(), 30000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    achievements: {
      deep: true,
      handler() {
        this.achievements.forEach(a => {
          let setCustomToAuto =
            disp.IsNullorWhitespace(a.displayCustom) ||
            a.displayCustom === a.displayAuto;

          a.displayAuto =
            a.background +
            " " +
            a.responsibility +
            " " +
            a.actionResult +
            " " +
            a.measurement;

          if (setCustomToAuto) {
            a.displayCustom = a.displayAuto;
          }
        });
      }
    }
  },
  methods: {
    save() {
      this.achievements = this.achievements.filter(
        a => !disp.IsNullorWhitespace(a.displayAuto)
      );

      this.saveWork();
    },
    ...mapMutations("work", ["createEntry"]),
    ...mapActions("work", ["saveWork"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    launchDialog(id, text) {
      this.dialogDisplay = text;
      this.dialogId = id;
      this.dialog = true;
    },
    removeDialog() {
      this.removeAchievement(this.dialogId);
      this.dialog = false;
    },
    removeAchievement(id) {
      this.achievements = this.achievements.filter(item => item.id != id);
    },
    addAchievement() {
      if (this.achievements.length < 4) {
        this.achievements.push({
          background: "",
          responsibility: "",
          actionResult: "",
          measurement: "",
          displayAuto: "",
          displayCustom: "",
          useCustom: false,
          id: uuid.gen()
        });

        this.panel = this.achievements.length - 1;
        let navObj = {
          path: this.$router.currentRoute.path,
          hash: "panel-" + (this.achievements.length - 1)
        };
        this.$router.push(navObj);
      }
    },
    buildTitleString(item) {
      let len = 0;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          len = 50;
          break;
        case "sm":
          len = 70;
          break;
        case "md":
          len = 80;
          break;
        case "lg":
          len = 90;
          break;
        case "xl":
          len = 120;
          break;
      }

      return disp.Ellipsis(
        item.useCustom ? item.displayCustom : item.displayAuto,
        len
      );
    }
  },
  computed: {
    ...mapGetters("work", ["doesKeyExist"]),
    ...mapFields("work", {
      key: "key",
      companyName: "workGeneral.companyName",
      achievements: "achievementList"
    }),
    nextti() {
      return "20"; //todo: Fix me
    }
  }
};
</script>
